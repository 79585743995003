import React from "react";
import YourGirl from '../assets/imgs/19.png';
import HisGirl from '../assets/imgs/20.png';
import HerGirl from '../assets/imgs/24.png';
import MyGirl from '../assets/imgs/6.png';


const Part2 = () => {
  return (
    <div className="p-4 md:p-16">
      <h2
        className="font-apparel md:mb-4 mt-10 text-4xl font-extralight text-center text-gray-900 tracking-widest"
        style={{ textShadow: 'rgb(217 217 217) 4px 4px' }}
      >
        Augment your life, shop smarter with AI
      </h2>
      <p className="font-poppins text-sm md:text-base text-gray-600 tracking-wide text-center">Setup a <strong>Virtual Fitting Room</strong> at Your Online Store</p>
      <div className="grid md:grid-cols-8 lg:grid-cols-12">
        
        <div className="p-4 col-span-3 relative md:mt-10 2xl:w-full">
          <div className="relative">
            <img className="object-cover max-h-112 mx-auto" src={YourGirl} />
            <div className=" absolute top-0 bottom-0 left-0 right-0 hover:ease-linear hover:opacity-60" />
          </div>
        </div>
        <div className="p-4 col-span-3 relative md:mt-10 2xl:w-full">
          <div className="relative">
            <img className="object-cover max-h-112 mx-auto" src={MyGirl} />
            <div className=" absolute top-0 bottom-0 left-0 right-0 hover:ease-linear hover:opacity-60" />
          </div>
        </div>
        <div className="p-4 col-span-3 relative md:mt-10 2xl:w-full">
          <div className="relative">
            <img className="object-cover max-h-112 mx-auto" src={HerGirl} />
            <div className=" absolute top-0 bottom-0 left-0 right-0 hover:ease-linear hover:opacity-60" />
          </div>
        </div>
        <div className="p-4 col-span-3 relative  md:mt-10 2xl:w-9/12">
          <div className="relative">
            <img className="object-cover max-h-112 mx-auto" src={HisGirl} />
            <div className=" absolute top-0 bottom-0 left-0 right-0 hover:ease-linear hover:opacity-60" />
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Part2;
