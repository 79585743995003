import axios from "axios";
import { useEffect, useState } from "react";
import { api } from "../../config";
import ClothesListing from "../ClothesListing";
import { Loader } from "../Common/Loader";
import Modal from "../Common/Modal";
import DisplayModel from "../DisplayModel";
import Title from "../Handler/Title";
import NotificationModal from "../NotificationModal";

const Croptops = ({ isLoggedIn }) => {
  const [headerMarginTop, setHeaderMarginTop] = useState(0);
  const [clothLoading, setClothLoading] = useState(true);
  const [showTick, setShowTick] = useState(false);
  const [clothesList, setClothesList] = useState([]);
  const [clothesItem, setClothesItem] = useState(undefined);
  const [showClothes, setShowClothes] = useState(false);
  const [modelImage, setModelImage] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [pending, setPending] = useState(true);
  const [err, setErr] = useState(undefined);
  const [uploading, setUploading] = useState(false);
  const [output, setOutput] = useState(undefined);
  const [title, setTitle] = useState(null);
  const [loading, setLoading] = useState(false);

  const onClothesSelect = (item) => {
    setClothesItem(item);
    setShowTick(true);
  };

  const handleShowClothes = () => {
    setShowClothes((flag) => !flag);
  };

  const onModelImageChange = (evt) => {
    evt.preventDefault();

    if (evt.target.files[0]) {
      setUploading(true);
      axios
        .post(
          `${api}/upload_model`,
          { model: evt.target.files[0] },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          const errorString = res.data.error;
          if (
            errorString &&
            typeof errorString === "string" &&
            errorString !== ""
          ) {
            const resErr = errorString.split("!");
            setTimeout(() => {
              setErr(
                <div>
                  <span className="font-bold text-lg font-poppins text-red-600">
                    Try uploading a full size image.
                  </span>
                  <br />
                  {resErr.slice(0, 2).map((resE, index) => (
                    <li key={index} style={{ color: "navy" }}>
                      {resE}!
                    </li>
                  ))}
                </div>
              );
              setShowModal(true);
            }, 500);
          } else {
            setModelImage(res.data.model);
          }
        })
        .catch((err) => {
          setErr("Something went wrong...\n", err);
          setShowModal(true);
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const getImage = (evt) => {
    evt.preventDefault();
    if (modelImage && clothesItem) {
      setTimeout(() => {
        const dlLink = document.createElement("a");
        dlLink.href = document.getElementById("dlImg").getAttribute("src");
        dlLink.setAttribute("Download", "result.jpg");
        dlLink.click();
      }, 500);
    } else {
      setTimeout(() => {
        setErr("Upload an image and select a cloth first.");
        setShowModal(true);
      }, 1000);
    }
  };

  useEffect(() => {
    setClothLoading(true);
    setErr(null);
    axios
      .get(`${api}/clothes`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (res) {
        if (res.status === 200) {
          setClothLoading(false);
          let cl = [];
          res.data.clothes_json.forEach((item) => {
            if (item.category === "croptop") {
              cl.push(item);
            }
            setClothesList(cl);
            setTitle(<Title heading="Crop Tops " length={cl.length} />);
          });
        }
      })
      .catch(function (err) {
        setTimeout(() => {
          setClothLoading(false);
          setErr("Failed to fetch clothes. Reload again.");
          setShowModal(true);
        }, 1100);
      });
  }, [setClothLoading]);

  useEffect(() => {
    axios
      .get(`${api}/set_model`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (res) {
        if (res.status === 200) {
          let cl = [];
          res.data.model.forEach((item) => {
            if (item.category === "cropped") {
              setModelImage(item.src_img);
            }
          });
        }
      })
      .catch(function (err) {
        setTimeout(() => {
          // setClothLoading(false);
          setErr("Failed to fetch Model Image. Reload again.");
          // setShowModal(true);
        }, 1100);
      });
  }, [setModelImage]);

  useEffect(() => {
    let marginTop = document.getElementById("header").clientHeight;
    setHeaderMarginTop(marginTop);
  }, [headerMarginTop]);

  useEffect(() => {
    if (modelImage && clothesItem) {
      setLoading(true);
      const data = {
        model: modelImage,
        clothes: clothesItem,
      };

      axios
        .post(`${api}/predict`, data, {
          headers: {
            "Content-Type": "application/json",
            Accept: "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          setOutput(res.data.output);
          setLoading(false);
          setErr(undefined);
        })
        .catch((err) => {
          if (err.response.status === 429) {
            setLoading(false);
            setErr(
              <>
                <span className="block text-lg font-semibold">
                  Thank you for trying our product/service. We hope you've found
                  it valuable so far! We would love to discuss partnering with
                  you to bring our solution to more people. Would you be
                  interested in exploring a collaboration with us?{" "}
                  <a
                    className="text-[#ff3d00] hover:underline"
                    target="_blank"
                    href="https://ainoviqit.com/contact"
                  >
                    Contact here
                  </a>
                  {/* You have reached your credit limit for today.
              Please, Contact <a className="text-[#ff3d00] hover:underline" target="_blank" href="https://ainoviqit.com/contact">here</a> to get more credit. */}
                </span>
              </>
            );
          }
          setShowModal(true);
        })
        .finally(() => {
          setPending(true);
        });
    }
  }, [modelImage, clothesItem, setLoading]);

  return (
    <>
      <NotificationModal />
      <section className="invisible md:visible md:col-span-6 lg:col-span-7 xl:col-span-8 md:flex">
        {clothLoading ? (
          <Loader />
        ) : (
          <ClothesListing
            clothesList={clothesList}
            showTick={showTick}
            clothesItem={clothesItem}
            onClothesSelect={onClothesSelect}
            showClothes={showClothes}
            setShowClothes={setShowClothes}
            title={title}
            isLoggedIn={isLoggedIn}
          />
        )}
      </section>
      <section
        className="invisible md:visible md:col-span-6 lg:col-span-5 xl:col-span-4 md:flex md:justify-center"
        style={{ top: headerMarginTop }}
      >
        <DisplayModel
          onModelImageChange={onModelImageChange}
          modelImage={modelImage}
          output={output}
          uploading={uploading}
          handleShowClothes={handleShowClothes}
          getImage={getImage}
          headerMarginTop={headerMarginTop}
          loading={loading}
        />
      </section>
      <Modal showModal={showModal} setShowModal={setShowModal} title={"Error"}>
        <div className="block text-slate-500 text-sm font-poppins leading-relaxed">
          {err}
        </div>
      </Modal>
    </>
  );
};

export default Croptops;
