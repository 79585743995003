import React from "react";

export default function Modal({ showModal, setShowModal, title, children }) {
  const onEscKeyPress = (e) => {
    if (e.key === 'Escape') {
      setShowModal(false);
    }
  }

  const handleModalOutside = (e) => {
    if (e.target === e.currentTarget) {
      setShowModal(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', onEscKeyPress);

    return () => {
      document.removeEventListener('keydown', onEscKeyPress);
    }
  }, [])

  return (
    <>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto backdrop-blur-sm bg-[#00000080] fixed inset-0 z-50 outline-none focus:outline-none"
            onClick={handleModalOutside}
          >
            <div className="relative my-6 mx-auto max-w-lg w-full">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-slate-100 outline-none focus:outline-none">
                <div className="flex items-start justify-between p-2 border-b rounded-t bg-slate-200">
                  <h3 className="text-lg font-semibold">
                    {title}
                  </h3>
                  <button
                    className="bg-white text-red-500 px-2 py-1 font-poppins rounded-md shadow-md text-xl hover:font-bold tracking-wide outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-sm block outline-none focus:outline-none">
                      ESC
                    </span>
                  </button>
                </div>
                <div className="relative p-2 flex-auto">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
