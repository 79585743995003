import style from "./DisplayModel.module.css";
const Slider = ({ onPhotoVisibilityChange }) => {
    return (
        <>
            <input
                type="range"
                min={0}
                max={100}
                // defaultValue={value}
                aria-label="Percentage of before photo shown"
                className={style.slider}
                onChange={onPhotoVisibilityChange}
            />
            <div className="slider-line md:p-3" aria-hidden="true" />
            <div className="slider-button" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="currentColor" viewBox="0 0 256 256">
                    <rect width={256} height={256} fill="none" />
                    <line x1={128} y1={40} x2={128} y2={216} fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={16} />
                    <line x1={96} y1={128} x2={16} y2={128} fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={16} />
                    <polyline points="48 160 16 128 48 96" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={16} />
                    <line x1={160} y1={128} x2={240} y2={128} fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={16} />
                    <polyline points="208 96 240 128 208 160" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={16} />
                </svg>
            </div>
        </>
    );
}

export default Slider;