import React from "react";
import { api } from "../config";
import Buttons from "./Buttons";
import { DisplayModelLoader } from "./Common/DisplayModelLoader";
import { Loader } from "./Common/Loader";
import Instructions from "./Handler/Instructions";
import Slider from "./Slider";

const DisplayModel = ({
  onModelImageChange,
  modelImage,
  uploading,
  firstUploading,
  showTick,
  output,
  handleShowClothes,
  getImage,
  headerMarginTop,
  loading,
  showModal,
  clothesItem,
}) => {
  const uploadRef = React.useRef();
  const onPhotoVisibilityChange = (evt) => {
    const container = document.querySelector(".container");
    container.style.setProperty("--position", `${evt.target.value}%`);
  };

  return (
    <div className="invisible md:visible md:fixed md:mt-3">
      <div className="visible w-full absolute md:static">
        <div className="p-3 md:p-0 flex flex-col justify-center justify-items-center">
          <div className="border border-gray-200 rounded-lg boxShadow">
            
            <div className=" flex fixed sm:relative justify-center m-auto w-full xs:w-96 h-112 min-h-112 sm:w-96 md:min-h-full">
              {output === undefined &&
                modelImage !== undefined &&
                loading === false && (
                  <div className="">
                    <img
                      alt="output"
                      className="w-full absolute inset-0 object-cover h-full md:h-112 md:p-3"
                      src={`${api}/${modelImage}`}
                    />
                  </div>
                )}
              {loading === true ? (
                <div className="flex h-full justify-center items-center">
                  <img
                    alt="output"
                    className="w-full absolute inset-0 object-cover h-full md:h-112 md:p-3"
                    src={`${api}/${modelImage}`}
                  />
                  <DisplayModelLoader />
                </div>
              ) : uploading === true ? (
                <div className="flex h-full bg-white z-99 w-full justify-center text-center items-center">
                  <Loader />
                </div>
              ) : (
                <div className="container">
                  <div className="w-full h-full">
                    <img
                      alt="output"
                      className="image-after slider-image md:p-4"
                      src={`${api}/${modelImage}`}
                    />
                    {!output ? (
                      <div className="flex w-full h-full justify-center text-center items-center">
                        <h3 className="text-white w-full p-2 font-normal font-poppins bg-gradient-to-b from-transparent to-gray-900 rounded-b-md">
                          Select a clothes and try...
                        </h3>
                      </div>
                    ) : (
                      <img
                        alt="output"
                        className="image-before slider-image md:p-3"
                        src={output}
                        id="dlImg"
                      />
                    )}
                    {output ? (
                      <Slider
                        onPhotoVisibilityChange={onPhotoVisibilityChange}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </div>
            <Buttons
              onModelImageChange={onModelImageChange}
              uploadRef={uploadRef}
              handleShowClothes={handleShowClothes}
              getImage={getImage}
            />
          </div>
          <Instructions headerMarginTop={headerMarginTop} />
        </div>
      </div>
    </div>
  );
};

export default DisplayModel;
