import React, { useEffect, useState } from 'react';

export const DisplayModelLoader = () => {
  const [showScanner, setShowScanner] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowScanner(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleScannerAnimationEnd = () => {
    setShowScanner(false);
  };

  return (
    <>
      {showScanner && (
        <div className='scanner' />
      )}
    </>
  )
}
