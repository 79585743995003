import React from 'react'

export const Footer = () => {
  return (
    <footer className="p-4 block border-t bg-white text-center">
      <span className="text-slate-500 text-xs font-poppins">
        &copy; Copyright 2023{" "}
        <a
          href="https://ainoviqit.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          AinoviQ
        </a>
      </span>
    </footer>
  )
}
