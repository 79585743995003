import React, { useEffect, useState } from "react";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import AppView from "./components/AppView";
// import { Popup } from './components/Common/Popup';
import Camitops from "./components/Entities/Camitops";
import Croptops from "./components/Entities/Croptops";
import Fancy from "./components/Entities/Fancy";
import LongSleevesTshirt from "./components/Entities/LongSleevesTshirt";
import Tanktops from "./components/Entities/Tanktops";
import Tshirt from "./components/Entities/Tshirt";
import Error from "./components/Handler/Error";
import Wrapper from "./components/Handler/Wrapper";
import SignUpUi from "./components/SignUpUi";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [tokenExpirationTimer, setTokenExpirationTimer] = useState(null);

  const resetTokenExpirationTimer = () => {
    if (tokenExpirationTimer) {
      clearTimeout(tokenExpirationTimer);
    }
    const newTimer = setTimeout(() => {
      setIsLoggedIn(false);
      localStorage.removeItem("accessToken");
    }, 1000 * 60 * 60);
    setTokenExpirationTimer(newTimer);
  };

  useEffect(() => {
    const handleUserActivity = () => {
      resetTokenExpirationTimer();
    };
    // Add event listeners for various user interactions
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keypress", handleUserActivity);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keypress", handleUserActivity);
    };
  }, [tokenExpirationTimer]);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      setIsLoggedIn(true);
    }
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Wrapper>
          <Outlet />
        </Wrapper>
      ),
      errorElement: <Error />,
      children: [
        {
          path: "/",
          element: <AppView />,
        },
        {
          path: "register",
          element: (
            <SignUpUi
              isLoggedIn={isLoggedIn}
              setIsLoggedIn={setIsLoggedIn}
              resetTokenExpirationTimer={resetTokenExpirationTimer}
            />
          ),
        },
        {
          path: "tshirts",
          element: <Tshirt isLoggedIn={isLoggedIn} />,
        },
        {
          path: "fullsleeves",
          element: <LongSleevesTshirt isLoggedIn={isLoggedIn} />,
        },
        {
          path: "tanktops",
          element: <Tanktops isLoggedIn={isLoggedIn} />,
        },
        {
          path: "croptops",
          element: <Croptops isLoggedIn={isLoggedIn} />,
        },
        {
          path: "fancy",
          element: <Fancy isLoggedIn={isLoggedIn} />,
        },
        {
          path: "camis",
          element: <Camitops isLoggedIn={isLoggedIn} />,
        },
      ],
    },
  ]);
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default App;
