import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaArrowRight, FaCheck } from "react-icons/fa";
import { api } from "../config";
import Button from "./Common/Button";

// clothes web view
const ClothesWeb = ({
  clothesList,
  onClothesSelect,
  showTick,
  clothesItem,
  title,
  headerMarginTop,
  isLoggedIn,
}) => {
  return (
    <div className="hidden none md:block items-center mx-auto px-8">
      {clothesList.length > 0 && (
        <>
          {title}
          <div className="grid grid-cols-2 gap-1 my-4 lg:grid-cols-3 xl:grid-cols-4">
            {isLoggedIn
              ? clothesList.map((item) => (
                  <div key={item.key} className="w-full hover_dg">
                    <div className="relative">
                      <img
                        alt={item.key}
                        onClick={() => onClothesSelect(item)}
                        className="w-full hover:scale-110 ease-in duration-500"
                        name={item.key}
                        src={`${api}/${item.clothes_original}`}
                      />
                      {showTick &&
                      item.clothes_original === clothesItem.clothes_original ? (
                        <div className="absolute -top-0 -right-0 w-6 h-6 flex justify-center content-center items-center bg-white border border-orange-600">
                          <FaCheck className="text-orange-600 p-1" size={22} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))
              : clothesList.slice(0, 8).map((item) => (
                  <div key={item.key} className="w-full hover_dg">
                    <div className="relative">
                      <img
                        alt={item.key}
                        onClick={() => onClothesSelect(item)}
                        className="w-full hover:scale-110 ease-in duration-500"
                        name={item.key}
                        src={`${api}/${item.clothes_original}`}
                      />
                      {showTick &&
                      item.clothes_original === clothesItem.clothes_original ? (
                        <div className="absolute -top-0 -right-0 w-6 h-6 flex justify-center content-center items-center bg-white border border-orange-600">
                          <FaCheck className="text-orange-600 p-1" size={22} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
          </div>
          <Button headerMarginTop={headerMarginTop} isLoggedIn={isLoggedIn} />
        </>
      )}
    </div>
  );
};
// clothe web view ending
const delay = 1;

// clothe mobile view
const ClothesMobile = ({
  clothesList,
  scrollHorizontally,
  scrollRef,
  showClothes,
  setShowClothes,
  onClothesSelect,
  showTick,
  clothesItem,
  isLoggedIn,
}) => {
  const [counter, setCounter] = useState(0);
  const timer = useRef(null);

  useEffect(() => {
    timer.current = setInterval(() => setCounter((v) => v + 1), delay * 1000);
    return () => clearInterval(timer.current);
  }, [setShowClothes]);

  useEffect(() => {
    if (counter === 5) {
      setShowClothes(false);
    }
  }, [counter, setShowClothes]);

  return (
    <div
      className={`clear-both sm:absolute md:relative left-0 right-0 bottom-11 m-3 z-99 fixed ${
        showClothes ? "visible " : "invisible md:hidden none"
      }`}
      onTouchStart={() => {
        setShowClothes(true);
      }}
      onTouchEnd={() => {
        setCounter(0);
      }}
      onClick={() => {
        setShowClothes(true);
      }}
      onMouseMove={() => {
        setCounter(0);
      }}
    >
      <div className="flex justify-start">
        <h3 className="font-semibold text-slate-600 tracking-wide">
          Choose your clothes
        </h3>
      </div>
      {clothesList.length > 0 && (
        <div className="flex">
          <div
            className="flex overflow-x-scroll scroll-smooth bottom-16 "
            ref={scrollRef}
          >
            <button
              onClick={() => scrollHorizontally(-window.innerWidth)}
              className="absolute block outline-none px-2 top-0 bottom-0 z-10 left-0"
            >
              {" "}
              <FaArrowLeft />{" "}
            </button>
            <button
              onClick={() => scrollHorizontally(window.innerWidth)}
              className="absolute block outline-none px-2 top-0 bottom-0 z-10 right-0"
            >
              {" "}
              <FaArrowRight />{" "}
            </button>

            {isLoggedIn
              ? clothesList.map((item) => (
                  <div key={item.key} className="w-24 mr-1 flex-shrink-0">
                    <div className="relative ">
                      <img
                        alt={item.key}
                        onClick={() => onClothesSelect(item)}
                        className="w-full clths hover:scale-75 ease-in duration-500"
                        name={item.key}
                        src={`${api}/${item.clothes_original}`}
                      />
                      {showTick &&
                      item.clothes_original === clothesItem.clothes_original ? (
                        <div className="absolute -top-0 -right-0 w-6 h-6 flex justify-center content-center items-center bg-white border border-orange-600">
                          <FaCheck className=" text-orange-600 p-1" size={22} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))
              : clothesList.slice(0, 8).map((item) => (
                  <div key={item.key} className="w-24 mr-1 flex-shrink-0">
                    <div className="relative">
                      <img
                        alt={item.key}
                        onClick={() => onClothesSelect(item)}
                        className="w-full clths hover:scale-75 ease-in duration-500"
                        name={item.key}
                        src={`${api}/${item.clothes_original}`}
                      />
                      {showTick &&
                      item.clothes_original === clothesItem.clothes_original ? (
                        <div className="absolute -top-0 -right-0 w-6 h-6 flex justify-center content-center items-center bg-white border border-orange-600">
                          <FaCheck className=" text-orange-600 p-1" size={22} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))}
            <Button isLoggedIn={isLoggedIn} />
          </div>
        </div>
      )}
    </div>
  );
};
// clothe mobile view ending

const ClothesListing = ({
  clothesList,
  showTick,
  clothesItem,
  onClothesSelect,
  showClothes,
  setShowClothes,
  title,
  isLoggedIn,
}) => {
  const scrollRef = React.useRef();

  const scrollHorizontally = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
  };
  return (
    <>
      {/* Web view */}
      <ClothesWeb
        clothesList={clothesList}
        onClothesSelect={onClothesSelect}
        showTick={showTick}
        clothesItem={clothesItem}
        title={title}
        isLoggedIn={isLoggedIn}
      />

      {/* Mobile view */}
      <ClothesMobile
        clothesList={clothesList}
        scrollHorizontally={scrollHorizontally}
        scrollRef={scrollRef}
        showClothes={showClothes}
        setShowClothes={setShowClothes}
        onClothesSelect={onClothesSelect}
        showTick={showTick}
        clothesItem={clothesItem}
        isLoggedIn={isLoggedIn}
      />
    </>
  );
};

export default ClothesListing;
