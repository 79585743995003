import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../config";
import "./SignUpUi.css";

function SignUpUi({ isLoggedIn, setIsLoggedIn, resetTokenExpirationTimer }) {
  const [display, setDisplay] = useState(true);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleUsername = (e) => {
    setUsername(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleCountry = (e) => {
    setCountry(e.target.value);
  }
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    try {
      const data = {email, password}
      const response = await axios.post(`${api}/user/login/`, data);
      localStorage.setItem("accessToken", response.data.access_token);
      resetTokenExpirationTimer();
      setIsLoggedIn(true);
      navigate(-1);
    } catch (error) {
      if (error.response.status === 400) {
        alert(error.response.data.message);
      } else if (error.response.status === 403) {
        alert(error.response.data.message);
      } else if (error.response.status === 401) {
        alert(error.response.data.message);
      } else if (error.response.status === 500) {
        alert(error.response.data.message);
      }
    }
  };

  const handleSignup = async () => {
    try {
      let data = { username, email, password, country}
      const response = await axios.post(`${api}/user/`, data);
      setDisplay(false);
    } catch (error) {
      if (error.response.status === 400) {
        alert(error.response.data.message);
      } else if (error.response.status === 403) {
        alert(error.response.data.message);
      } else if (error.response.status === 401) {
        alert(error.response.data.message);
      } else if (error.response.status === 500) {
        alert(error.response.data.message);
      }
    }
  };
  return (
    <>
      <div
        className=" h-96 xl:h-112 w-[90%] md:w-1/2 bg-white m-auto col-span-12 text-center min-h-fit"
        style={{ boxShadow: "0px 2px 20px 5px #eee" }}
      >
        <div className="">
          <div className={!display ? "signin_padding" : "main_button"}>
           
            {/* <button
              className="text-slate-500 bg-white h-9 w-1/3 mr-4 rounded-sm font-gfs text-3xl"
              onClick={() => setDisplay(true)}
            >
              Join with us
            </button> */}
            {
            display ? (
              
              <button
                className=" text-slate-500 bg-white ml-2  hover:text-slate-800 font-gfs text-3xl"
                onClick={() => setDisplay(false)}
              >
                Join with us
              </button>
            ): (
             
              <button
                className=" text-slate-500 bg-white ml-2  hover:text-slate-800 font-gfs text-3xl"
                onClick={() => setDisplay(true)}
              >
                Signin here
              </button>
            )
          }
          </div>
          {display ? (
            <div className="mb-4">
              <h1 className="text-white  mt-4 mb-4">Sign Up</h1>
              <div>
                <div className=" mb-4">
                  <input
                    placeholder="Username"
                    className=" w-4/5 h-8 p-4 pt-5 pb-5 rounded-sm border border-[#eee] outline-none"
                    value={username}
                    onChange={handleUsername}
                  />
                </div>
                <div className=" mb-4">
                  <input
                    placeholder="Email"
                    type="email"
                    className=" w-4/5 h-8 p-4 pt-5 pb-5 rounded-sm outline-none border border[#eee]"
                    value={email}
                    onChange={handleEmail}
                  />
                </div>
                <div className=" mb-4">
                  <input
                    placeholder="Country"
                    type="text"
                    className=" w-4/5 h-8 p-4 pt-5 pb-5 rounded-sm outline-none border border[#eee]"
                    value={country}
                    onChange={handleCountry}
                  />
                </div>
                <div className=" mb-4">
                  <input
                    type="password"
                    placeholder="Password"
                    className=" w-4/5 h-8 p-4 pt-5 pb-5 rounded-sm outline-none border border[#eee]"
                    value={password}
                    onChange={handlePassword}
                  />
                </div>
                <button
                  className=" h-9 w-4/5 bg-slate-500 text-white rounded-sm font-poppins"
                  onClick={handleSignup}
                >
                  Signup
                </button>
              </div>
            </div>
          ) : (
            <div className="mb-4">
              <h1 className="text-white mt-4 mb-4">Sign In</h1>
              <div>
                <div className=" mb-4">
                  <input
                    placeholder="Email"
                    className=" w-4/5 h-8 p-4 pt-5 pb-5 rounded-sm outline-none border border[#eee]"
                    value={email}
                    onChange={handleEmail}
                  />
                </div>
                <div className=" mb-4">
                  <input
                    type="password"
                    placeholder="Password"
                    className=" w-4/5 h-8 p-4 pt-5 pb-5 rounded-sm outline-none border border[#eee]"
                    value={password}
                    onChange={handlePassword}
                  />
                </div>
                <button
                  className=" h-9 w-4/5 bg-slate-500 text-white rounded-sm font-poppins"
                  onClick={handleLogin}
                >
                  Signin
                </button>
              </div>
            </div>
          )}
          {
            display ? (
              <span className="text-bs text-slate-500 font-gfs">Already registered? 
              <button
                className=" text- bg-white ml-2  hover:text-slate-800"
                onClick={() => setDisplay(false)}
              >
                Signin here
              </button></span>
            ): (
              <span className="text-bs text-slate-500 font-gfs">Don't have an account? 
              <button
                className=" text- bg-white ml-2  hover:text-slate-800"
                onClick={() => setDisplay(true)}
              >
                Signup here
              </button></span>
            )
          }
          
        </div>
      </div>
    </>
  );
}
export default SignUpUi;
