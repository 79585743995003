import React from "react";

const Buttons = ({ onModelImageChange, uploadRef, handleShowClothes, getImage }) => {
  return (
    <div className="px-6">
      <div className='flex justify-evenly fixed md:relative bottom-0 right-0 left-0 m-3 md:m-0 md:my-3'>
        <div className='flex w-full justify-center'>
          <input
            onChange={onModelImageChange}
            className="hidden"
            id="file-upload"
            type="file"
            name="model"
            autoComplete="off"
            accept='image/*'
            ref={uploadRef}
          />
          <button
            className='w-full mr-1.5 px-2 py-2 font-poppins text-sm rounded-xl shadow-sm outline-none bg-gradient-to-b from-slate-100 to-slate-200 hover:shadow-inner text-slate-600'
            onClick={() => { uploadRef.current.click() }}
          >
            <span>Upload Photo</span>
          </button>
        </div>
        <div className='flex w-full justify-center'>
          <button
            className='hidden md:block w-full ml-1.5 px-2 py-2 font-poppins text-sm rounded-xl shadow-sm outline-none bg-gradient-to-b from-slate-100 to-slate-200 hover:shadow-inner text-slate-600'
            onClick={getImage}
          >
            <span>Download</span>
          </button>
          <button
            className='md:hidden w-full ml-1.5 px-4 py-2 font-poppins rounded-xl shadow-sm outline-none bg-gradient-to-b from-slate-100 to-slate-200 hover:shadow-inner text-slate-600'
            onClick={handleShowClothes}
          >
            <span>Select Clothes</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Buttons;