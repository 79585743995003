import React from 'react';
import { Outlet } from 'react-router-dom';
import { Footer } from '../Common/Footer';
// import Header from '../Common/Header';
import Navbar from '../Common/Navbar/Navbar';


const Wrapper = () => {
  const [headerMarginTop, setHeaderMarginTop] = React.useState(0);

  React.useEffect(() => {
    let marginTop = document.getElementById('header').clientHeight;
    setHeaderMarginTop(marginTop);
  }, [headerMarginTop])
  
  return (
    <div>
      <Navbar />
      <div className="max-w-8xl mx-auto min-h-screen md:grid md:grid-cols-12" style={{ marginTop: headerMarginTop }}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Wrapper;