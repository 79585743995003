import { CgClose } from 'react-icons/cg';
import Pose from '../../assets/imgs/pose.png';

export const Popup = ({ popup, setPopup}) => {
  return (
    <>
      {
        popup ?

          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto mb-6 mt-32 p-4 mx-auto max-w-2xl bg-slate-200 rounded-lg overflow-hidden">
              <div className='absolute top-2 right-2'>
                <button onClick={() => setPopup(false)} className='bg-red-600 p-1 text-white rounded-sm'>
                  <CgClose />
                </button>
              </div>
              <h3 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">Instructions</h3>
              <div className='mb-1 text-xs font-poppins'>
                <span>Take a photo like the picture given below</span>
                <span className='text-base text-red-600'>*</span>
              </div>
              <div className="w-60 mx-auto md:max-w-xs rounded overflow-hidden">
                <img className="w-48 mx-auto" src={Pose} alt="Capturing guide" />
                <ul className="max-w-md mt-6 font-poppins leading-10 space-y-1 text-black">
                  <li className="text-xs mb-1 tracking-wide"><span>Turn towards the camera with your full figure in sight.</span></li>
                  <li className="text-xs mb-1 tracking-wide"><span>Keep your arms straight down, without folding or crossing them.</span></li>
                  <li className="text-xs mb-1 tracking-wide"><span>Ensure sufficient lighting.</span></li>
                  <li className="text-xs mb-1 tracking-wide"><span>Make sure hair is tucked behind the shoulders.</span></li>
                </ul>
              </div>
            </div>
          </div>
          :
          ""
      }
    </>
  );
}
