import React from 'react';
import About from './About';
import Mission from './Mission';
import Part2 from './Part2';
import Whoami from './Whoami';


function AppView({isLoggedIn, handleLoginSuccess}) {
  return (
    <>
      <section className='col-span-full mb-4 md:mb-10'>
        <About />
      </section>
      <section className='col-span-full mb-4 md:mb-10'>
        <Part2 />
      </section>
      <section className='col-span-full mb-4 md:mb-10 bg-gradient-to-b from-white via-slate-50 to-indigo-50'>
        <Whoami />
      </section>
      <section className="col-span-full mb-4 md:mb-10">
        <Mission />
      </section>
    </>

  );
}

export default AppView;
