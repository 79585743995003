import React from "react";
import We from './Whoami.module.css';


const Whoami = () => {
  return (
    <div className="p-4 md:p-16 relative">

      <h1
        className="font-gfs mb-8 md:mb-16 text-4xl md:text-6xl font-bold text-slate-900 tracking-widest"
      >
        <span>Who Are</span> {" "}
        <span className="relative text-slate-500">
          <span >We?</span>
          {/* <div className={We.line}></div> */}

          <svg className="absolute left-0 right-0 -bottom-1 geeks" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 150" preserveAspectRatio="none">
            <path 
              className={We.path}
              d="M7.7,145.6C109, 125, 299.9, 116.2,401, 121.3c42.1, 2.2, 87.6, 11.8, 87.3 , 25.7">
              </path>
          </svg>
        </span>

      </h1>
      <div>
        <h2 className="font-poppins font-normal md:text-4xl text-black text-left mb-8">
          We're a full-fledged Artificial Intelligence driven startup.
        </h2>
        <h2 className="font-poppins font-normal md:text-4xl text-black text-left mb-8">
          Our startup is a pioneer in the field of data science, and we offer AI-powered solutions through our in-house R&D department.
        </h2>
        <h2 className="font-poppins font-normal md:text-4xl text-black text-left mb-8">
          Using the power of Artificial Intelligence to completely transform all industries on a global platform.
        </h2>
      </div>
    </div>
  );
};

export default Whoami;
