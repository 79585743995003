export const links = [
  {
    name: "Trial Room",
    submenu: true,
    sublinks: [
      {
        Head: "T-Shirts",
        sublink: [
          { name: "Short Sleeves", link: "/tshirts" },
          { name: "Long Sleeves", link: "/fullsleeves" },
          { name: "Crop Tees", link: "/croptops" }
        ],
      },
      {
        Head: "Tops",
        sublink: [
          { name: "Camis", link: "/camis" },
          { name: "Tanks", link: "/tanktops" }
        ],
      },
      {
        Head: "Fancy",
        sublink: [
          { name: "Fancy", link: "/fancy" }
        ],
      }
    ]
  },
];
