import React from "react";
import { FaRegEye, FaRocket } from 'react-icons/fa';
import { GiArcheryTarget } from 'react-icons/gi';

const styles = {
  allflex: {
    flexBasis: 0,
    flexGrow: 1
  }
}


const Mission = () => {
  return (
    <div className="p-4 md:p-16">
      <div className="block md:relative w-full">
        <div className="block">
          <div className="block md:flex md:justify-around">
            <div className="p-4 ml-0 md:ml-5 mt-0 mb-4 md:mb-0 md:-mt-12 bg-white shadow-md rounded-lg" style={styles.allflex}>
              <div className="w-16 h-16 md:w-24 md:h-24 mx-auto mb-4 bg-white rounded-full border-8 border-gray-600 flex justify-center">
                <div className=" w-8 h-8 md:w-22 md:h-22 m-auto">
                  <FaRocket className="text-slate-600" size={32} />
                </div>
              </div>
              <div className="md:px-4">
                <h1 className="text-2xl text-center font-medium font-poppins text-slate-600 uppercase tracking-wider">Mission</h1>
                <p className="text-slate-500 leading-8 font-poppins tracking-wide text-justify">
                  Our mission is to empower businesses by revolutionizing the way customers interact with products using the latest advancements in deep learning
                  and computer vision. We strive to provide our clients with cutting-edge technology solutions that enhance the customer experience,
                  increase sales, and improve overall business performance.
                </p>
              </div>
            </div>
            <div className="p-4 ml-0 mr-0 md:ml-5 md:mr-5 mt-0 mb-4 md:mb-0 md:-mt-12 bg-white shadow-md rounded-lg" style={styles.allflex}>
              <div className="w-16 h-16 md:w-24 md:h-24 mx-auto mb-4 bg-white rounded-full border-8 border-gray-600 flex justify-center">
                <div className=" w-8 h-8 md:w-22 md:h-22 m-auto">
                  <FaRegEye className="text-slate-600" size={32} />
                </div>
              </div>
              <div className="md:px-4">
                <h1 className="text-2xl text-center font-medium font-poppins text-slate-600 uppercase tracking-wider">Vision</h1>
                <p className="text-slate-500 leading-8 font-poppins tracking-wide text-justify">
                  To discover, develop & deliver innovative software that provides high level solution to the people with reliability & makes everyday life easier.
                  Brings artificial intelligence and apply machine learning to the new domain. Also develops deep learning technology to innovate sophisticated software.
                  Furthermore, provides advance analytics visualization in e-commerce sectors.
                </p>
              </div>
            </div>
            <div className="p-4 mr-0 md:mr-5 mt-0 mb-4 md:mb-0 md:-mt-12 bg-white shadow-md rounded-lg" style={styles.allflex}>
              <div className="w-16 h-16 md:w-24 md:h-24 mx-auto mb-4 bg-white rounded-full border-8 border-gray-600 flex justify-center">
                <div className=" w-8 h-8 md:w-22 md:h-22 m-auto">
                  <GiArcheryTarget className="text-slate-600" size={32} />
                </div>
              </div>
              <div className="md:px-4">
                <h1 className="text-2xl text-center font-medium font-poppins text-slate-600 uppercase tracking-wider">Goal</h1>
                <p className="text-slate-500 leading-8 font-poppins tracking-wide text-justify">
                  Transforming all industries through the power of artificial intelligence on global platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
