import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
// import Button from "../Button";
import Logo from '../../../assets/icons/logo_small.png';
import NavLinks from "./NavLinks";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  // const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  return (
    <nav className="bg-gradient-to-r from-slate-50 via-blue-50 to-indigo-50 border-b fixed w-full top-0 left-0 py-3 z-[9999]" id="header" ref={dropdownRef}>
      <div className="md:flex justify-between px-4 md:px-16">
        <div className="flex">
          <div className="z-50 md:w-auto w-full flex justify-between">
            <Link to={"/"} className="px-3 font-poppins font-light text-slate-800 hover:text-slate-600 inline-block">
              <img src={Logo} alt="logo" className="md:cursor-pointer rounded-full w-12 h-12 md:w-8 md:h-8" />
            </Link>
            <div className="text-3xl mt-3 md:hidden" onClick={() => setOpen(!open)}>
              <ion-icon name={`${open ? "close" : "menu"}`}></ion-icon>
            </div>
          </div>
          <ul className="md:flex hidden items-center gap-4 font-[Poppins]">
            <div>
              <li className="px-3 font-poppins font-light text-gray-800 hover:text-gray-600 inline-block">
                <Link to="/" className="uppercase">
                  Home
                </Link>
              </li>
            </div>
            <NavLinks />
          </ul>
          {/* Mobile nav */}
          <ul
            className={`md:hidden bg-white fixed w-9/12 top-0 bottom-0 overflow-y-auto py-24 pl-4 duration-500 ${open ? "left-0" : "left-[-100%]"}`}
          >
            <div className="md:py-0 py-2">
              <li className="px-3 font-poppins font-light text-gray-800 inline-block">
                <Link to="/" className="uppercase">
                  Home
                </Link>
              </li>
            </div>
            <NavLinks />
          </ul>
        </div>
        <div className="md:pr-3 hidden md:block fadeInRight">
          <Link
            to={'/tshirts'}
            className="font-poppins font-light bg-[#ff3d00] text-white hover:text-white inline-block rounded-sm p-1 px-3 hover:-translate-y-1 hover:scale-110 duration-500"
          >
            Fit yourself
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
