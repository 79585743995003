import React from "react";

export const Loader = () => {
  const [headerMarginTop, setHeaderMarginTop] = React.useState(0);

  React.useEffect(() => {
    let marginTop = document.getElementById('header').clientHeight;
    setHeaderMarginTop(marginTop);
  }, [headerMarginTop])

  let circleCommonClasses = 'h-2.5 w-2.5 bg-current rounded-full';

  return (
    <div className='flex w-full h-full min-h-screen justify-center items-center' style={{ marginTop: -headerMarginTop }}>
      <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
      <div className={`${circleCommonClasses} mr-1 animate-bounce 200`}></div>
      <div className={`${circleCommonClasses} animate-bounce 400`}></div>
    </div>
  );
};