import React, { useEffect, useRef } from 'react';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { Popup } from '../Common/Popup';

const Instructions = ({ headerMarginTop }) => {
  const [popup, setPopup] = React.useState(false);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const modalRef = useRef();

  useEffect(() => {
    // Add event listener to document when modal is open
    if (popup) {
      document.addEventListener('click', handleClickOutside);
    }

    // Remove event listener from document when modal is closed
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [popup]);

  const handleClickOutside = (event) => {
    // Check if the click occurred outside the modal
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setPopup(false);
    }
  };
  React.useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    screenWidth > 767 ?
      <div className="mt-1 z-99 cursor-pointer block">
        <div className="flex">
          <Popup popup={popup} setPopup={setPopup} />
          <div>
            <h3 className="mb-2 text-lg font-semibold text-gray-900">Instructions</h3>
          </div>
          <div
            className="select-none rounded-full ml-2 mb-3 text-center text-slate-500 hover:text-slate-400 my-auto"
            onClick={() => setPopup(true)}>
            <AiFillQuestionCircle />
          </div>
        </div>
        <div className="mb-3">
          <ul className="max-w-md font-poppins space-y-1 text-black list-disc list-inside">
            <li className="text-xs">Turn towards the camera with your full figure in sight.</li>
            <li className="text-xs">Keep your arms straight down, without folding or crossing them.</li>
            <li className="text-xs">Ensure sufficient lighting.</li>
            <li className="text-xs">Make sure your hair is tucked behind the shoulders.</li>
          </ul>
        </div>
      </div>
      :
      <div className="fixed z-99 top-0 left-0 right-0" style={{ marginTop: headerMarginTop }}>
        <Popup popup={popup} setPopup={setPopup} />
        <div
          className="select-none rounded-full float-right mr-1 mt-1 flex text-slate-600 hover:text-slate-400 my-auto"
          onClick={() => setPopup(true)}> <span className="text-xs mr-1 font-bold">Instructions</span>
          <AiFillQuestionCircle />
        </div>
      </div>
  );
}

export default Instructions;