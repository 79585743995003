import React from "react";

import { useNavigate } from "react-router-dom";

const Button = ({ headerMarginTop, isLoggedIn }) => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const navigate = useNavigate();
  const navigateToSignUp = () => {
    navigate('/register')
      
  };
  return (
    <div className="mt-1 z-99 cursor-pointer block">
      <div className="flex">
        <div className="mt-6 md:mt-0 fadeInRight">
          {
            isLoggedIn ?
            ""
           : <h3
          className="mb-2 text-sm md:text-lg w-32 md:w-44 text-center font-gfs p-3 m-2 border rounded-lg shrink-0 font-semibold text-gray-900 bg-gray-200 hover:-translate-y-1 hover:scale-110 duration-500"
          onClick={navigateToSignUp}
        >
          Try More
        </h3> 
        }
          
        </div>
      </div>
    </div>
  );
};

export default Button;
