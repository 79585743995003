import React, { useEffect, useState } from 'react';
import { Cookies } from "react-cookie";
import Pose from '../assets/imgs/pose.png';
import Modal from './Common/Modal';

function NotificationModal() {
  const [showModal, setShowModal] = useState(false);
  const cookies = new Cookies('registered');

  useEffect(() => {


    if (cookies.get('registered')) {
      setTimeout(()=> {
        setShowModal(true);
      },1000 * 1)
    } else {
      const paths = ["/tshirts", "/fullsleeves", "/tanktops", "/croptops", "/fancy", "/camis"];
      paths.forEach(path => {
        cookies.set('registered', true, {
          path: path,
        });
      });
      setTimeout(() => {
        setShowModal(true);
      }, 1000 * 1);
    }
  }, []);


  useEffect(() => {
    setTimeout(() => {
      cookies.remove('registered');
    }, 1000 * 60 * 5)
  })

  
  return (
    <>
      {showModal &&
        < div className='relative'>
          <Modal showModal={showModal} setShowModal={setShowModal} title={"Instructions"}>
            <div className='m-2'>
              <span className='font-poppins text-md md:text-lg'>Take a photo like the picture given below</span>
            </div>
            <div className='m-2 '>
              <div className='w-48 h-auto shadow-md rounded-lg overflow-hidden mx-auto'>
                <img src={Pose} alt="Capturing Guide" className='mx-auto w-full h-full' />
              </div>
              <div className=''>
                <ul className='list-disc list-inside text-sm leading-7 font-poppins font-light tracking-normal'>
                  <li><span>Turn towards the camera with your full figure in sight.</span></li>
                  <li><span>Keep your arms straight down, without folding or crossing them.</span></li>
                  <li><span>Ensure sufficient lighting.</span></li>
                  <li><span>Make sure hair is tucked behind the shoulders.</span></li>
                </ul>
              </div>
            </div>
          </Modal >
        </div >
      }
    </>
  );
}

export default NotificationModal;
