import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import MyGirl from './../assets/imgs/1.png';
import YourGirl from './../assets/imgs/2.png';
import OurGirl from './../assets/imgs/image.png';
import Girl from './../assets/imgs/image.gif';


const About = () => {
  useEffect(() => {
    let words = document.querySelectorAll(".word");
    words.forEach(word => {
      let letters = word.textContent.split("");
      word.textContent = "";
      letters.forEach(letter => {
        let span = document.createElement("span");
        span.textContent = letter;
        span.className = "letter";
        word.append(span);
      });
    });

    let currentWordIndex = 0;
    let maxWordIndex = words.length - 1;
    words[currentWordIndex].style.opacity = "1";

    let rotateText = () => {
      let currentWord = words[currentWordIndex];
      let nextWord =
        currentWordIndex === maxWordIndex ? words[0] : words[currentWordIndex + 1];

      Array.from(currentWord.children).forEach((letter, i) => {
        setTimeout(() => {
          letter.className = "letter out";
        }, i * 80);
      });

      nextWord.style.opacity = "1";
      Array.from(nextWord.children).forEach((letter, i) => {
        letter.className = "letter behind";
        setTimeout(() => {
          letter.className = "letter in";
        }, 340 + i * 80);
      });
      currentWordIndex =
        currentWordIndex === maxWordIndex ? 0 : currentWordIndex + 1;
    };

    rotateText();
    setInterval(rotateText, 4000);
  }, [])

  return (
    <div className="p-4 md:p-16">
      <div className="grid lg:grid-cols-12">
        <div className="col-span-6 relative 2xl:w-full">
          <div className="sm:translate-y-0 translate-y-0 xl:translate-y-1/4">
            <div className="text-slate-900 lg:mb-12">
              <div className="rotating-text font-gfs mb-20">
                <h1>Give Yourself a Better</h1>{" "}
                <h1>
                  <span className="word alizarin">Feel</span>
                  <span className="word wisteria">Fit</span>
                  <span className="word peter-river">Look</span>
                </h1>
              </div>

              <h3 className="text-base font-poppins text-slate-600 tracking-wide mb-4">with our <strong>"Virtual Try-On Clothes"</strong></h3>
              <h3 className="text-base font-poppins text-slate-600 tracking-wide">Unlock the power of your business with Artificial Intelligence.</h3>

            </div>
            <div className="justify-center hidden items-center text-center lg:flex">
              <Link
                to={'/tshirts'}
                className="font-poppins text-xl px-3 py-2 bg-[#ff3d00] text-white hover:text-white inline-block hover:-translate-y-1 hover:scale-110 duration-500"
              >
                Fit yourself
              </Link>
            </div>
          </div>
        </div>
        <div className="col-span-6 relative">
          <div className="relative p-4 md:p-0 w-full xs:w-4/5 sm:w-4/5 mx-auto mb-20">
            <div className="relative left-1/4 w-1/2 md:w-[30%] lg:left-20p lg:w-2/3 2xl:left-1/4 2xl:w-[40%]" style={{ marginTop: '25%' }}>
              <div className="">
                <img src={Girl} className="w-full" />
              </div>
              <div className="absolute w-2/3 -left-20 md:-left-36 md:-top-32 lg:-left-32 xl:-left-40 -top-20">
                <img src={MyGirl} className="w-full" />
              </div>
              <div className="absolute w-1/2 -top-3 -right-20 md:-right-16 md:-top-44 md:w-[40%] 2xl:-top-44 2xl:-right-16 2xl:w-[45%]">
                <img src={YourGirl} className="w-full" />
              </div>
              <div className="absolute w-1/2 -bottom-12 -right-16 md:-right-24 xl:-right-28 2xl:-right-32 2xl:w-2/4">
                <img src={OurGirl} className="w-full" />
              </div>
            </div>
          </div>
          <div className="justify-center items-center lg:hidden text-center flex">
            <Link
              to={'/tshirts'}
              className="font-poppins text-xl px-3 py-2 bg-[#ff3d00] text-white hover:text-white inline-block hover:-translate-y-1 hover:scale-110 duration-500"
            >
              Fit yourself
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
